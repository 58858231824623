<template>
  <footer>
    <div
      class="l-footer"
      :class="{
        isLoginRelated: isLoginRelated,
      }"
    >
      <div class="l-footer__inner">
        <div class="l-footer__copys">
          <span class="l-footer__copys__copy"
            >Copyright © CBA Inc. All rights reserved.</span
          >
          <a href="/v_resources/assets/pdf/利用規約.pdf" target="_blank">利用規約</a>
          <router-link to="//www.cba-japan.co.jp/privacy/" target="_blank">プライバシーポリシー</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import loginRelated from "@/mixin/loginRelated";

export default {
  mixins: [loginRelated],
};
</script>
